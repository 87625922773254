<template>
  <footer>
    <div class="row">
      <div class="col-md-4 footer-column">
        <ul class="nav flex-column">
          <li class="nav-item">
            <span class="footer-title">Quick Links</span>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/Services#banner">Services</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/Classes#banner">Workshops</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/About">About</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/PrivacyPolicy">Privacy Policy</router-link>
          </li>
        </ul>
      </div>
      <div class="col-md-4 footer-column">
        <ul class="nav flex-column">
          <li class="nav-item" style="text-align: center; margin-bottom: 10px">
            <span class="footer-title">Social Media</span>
          </li>
          <div class="col">
            <ul class="list-inline social-buttons">
              <li class="list-inline-item">
                <a target="_blank" href="https://www.facebook.com/cwtchpottery">
                  <svg
                    fill="#9faec5"
                    width="22"
                    height="22"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"
                    />
                  </svg>
                </a>
              </li>
              <li class="list-inline-item">
                <a target="_blank" href="https://www.instagram.com/cwtchpottery">
                  <svg
                    fill="#9faec5"
                    width="22"
                    height="22"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1152 896q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm138 0q0 164-115 279t-279 115-279-115-115-279 115-279 279-115 279 115 115 279zm108-410q0 38-27 65t-65 27-65-27-27-65 27-65 65-27 65 27 27 65zm-502-220q-7 0-76.5-.5t-105.5 0-96.5 3-103 10-71.5 18.5q-50 20-88 58t-58 88q-11 29-18.5 71.5t-10 103-3 96.5 0 105.5.5 76.5-.5 76.5 0 105.5 3 96.5 10 103 18.5 71.5q20 50 58 88t88 58q29 11 71.5 18.5t103 10 96.5 3 105.5 0 76.5-.5 76.5.5 105.5 0 96.5-3 103-10 71.5-18.5q50-20 88-58t58-88q11-29 18.5-71.5t10-103 3-96.5 0-105.5-.5-76.5.5-76.5 0-105.5-3-96.5-10-103-18.5-71.5q-20-50-58-88t-88-58q-29-11-71.5-18.5t-103-10-96.5-3-105.5 0-76.5.5zm768 630q0 229-5 317-10 208-124 322t-322 124q-88 5-317 5t-317-5q-208-10-322-124t-124-322q-5-88-5-317t5-317q10-208 124-322t322-124q88-5 317-5t317 5q208 10 322 124t124 322q5 88 5 317z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </ul>
      </div>
      <div class="col-md-4 footer-column">
        <ul class="nav flex-column">
          <li class="nav-item">
            <span class="footer-title">CONTACT</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="tel:+447882525170">+44 7882 525170</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="mailto:cwtchpottery@hotmail.com"
              >cwtchpottery@hotmail.com</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="text-center"><i class="fas fa-ellipsis-h"></i></div>

    <div class="row text-center">
      <div class="col-md-12 box">
        <span class="copyright quick-links" style="color: grey"
          >Copyright &copy; Cwtch Pottery 2022 - Website design by <a target="_blank" href="https://gofodtech.co.uk/">Gofod Technologies</a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import { fasFont } from "@quasar/extras/fontawesome-v5";

export default {
  name: "Footer",
  created() {
    this.fasFont = fasFont;
  },
};
</script>

<style scoped>
a {
  color: #6c757d;
}

.list-inline-item {
  text-align: center;
}
a:hover svg {
  fill: #212529;
}

a:hover {
  color: #9faec5;
  text-decoration: none;
}

::selection {
  background: #9faec5;
  text-shadow: none;
}

footer {
  padding: 2rem 0;
  background-color: #212529;
}

.footer-column:not(:first-child) {
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .footer-column:not(:first-child) {
    padding-top: 0rem;
  }
}

.footer-column {
  text-align: center;
}
.footer-column .nav-item .nav-link {
  padding: 0.1rem 0;
}
.footer-column .nav-item span.nav-link {
  color: #6c757d;
}
.footer-column .nav-item span.footer-title {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
.footer-column .nav-item .fas {
  margin-right: 0.5rem;
}
.footer-column ul {
  display: inline-block;
}
@media (min-width: 768px) {
  .footer-column ul {
    text-align: left;
  }
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: #9faec5;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  border-radius: 100%;
  outline: 0;
  background-color: #1a1d20;
}

footer .quick-links {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.copyright {
  color: white;
  white-space: break-spaces;
}

.fa-ellipsis-h {
  color: white;
  padding: 2rem 0;
}
</style>
