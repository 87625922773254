<template>
  <div class="service">
    <img :src="require(`../${imgPath}`)" />
    <div class="subtext">
      <div class="service-title">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceCard",
  props: {
    title: String,
    imgPath: String,
    details: Array,
    bg: String,
  },
  computed: {
    bgcolor: function () {
      return this.bg ? this.bg : "#97b0c3"
    }
  }
};
</script>

<style scoped>
.service {
  height: 460px;
  width: 330px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  text-align: center;
  
  display: inline-block;
  text-align: -webkit-center;
}

.service img {
  border-radius: 0.8rem;
  margin: 2.5%;
  height: 95%;
  width: 95%;
  object-fit: cover;
}

.service .subtext {
  width: 60%;
  position: absolute;
  bottom: -3%;
  left: 20%;
  background-color: v-bind(bgcolor);
  border-radius: 0.2rem;
}

.service-title {
  padding-top: 0.5rem;
  font-size: 2.3rem;
  font-weight: bold;
  color: #354550;
  font-family: "Ballad";
}

.service-details {
  text-align: left;
  font-family: "Arial"
}

.service-details ul {
  list-style: none;
}
</style>