<template>
  <div class="section-body">
    <div id="banner" class="main-img-container wrapper mb-5">
      <img src="../img/front/learners3.webp" />
      <span class="text-center"> Workshops </span>
    </div>

    <div class="text">
      <div class="intro">
        <div class="row">
          <div class="col">
            <h2>About the class</h2>
            <p>
              Pottery classes are a great way to unwind, meet new friends and
              learn a new skill! Our classes are open to
              <b>all abilities and ages</b>!
              <br />
              In these classes of up to 16 participants, we will make something
              from scratch, in a fun, relaxed environment.
              <br />
              There are numerous <b>health benefits</b> of pottery, including
              stress-relief, improved dexterity and recovery of motor skills
              after brain trauma.
              <br />
              Read more about these <router-link to="/About">here</router-link>,
              alongside the story of how I got into pottery.
            </p>
          </div>
          <div class="col">
            <h2>Come along</h2>
            <ul>
              <li>Pre-booking essential</li>
              <li>
                We have pottery classes every Tuesday and Thursday evening and
                Friday morning
              </li>
              <li>Kids classes</li>
              <li>Disability-friendly classes</li>
              <li>
                Three weekly classes are held locally in Swansea, please  
                <a href="#contact-details" @click="this.$root.FlashContactDetails">get in touch</a>
                for more details
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="gallery py-5">
        <h2>See what our potters have made</h2>
        <div v-for="x in 9" :key="x" class="img-container">
          <GalleryImage :id="x" :path="'img/learners/' + x + '.webp'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GalleryImage from "../components/GalleryImage.vue";
export default {
  name: "Classes",
  components: {
    GalleryImage,
  },
};
</script>

<style scoped>
.text b {
  font-weight: bold;
}

.intro {
  font-size: 2rem;
}
</style>
