<template>
  <div class="section-body">
    <div class="text">
      <h1 style="text-align: center; padding: 1.5rem">Privacy Policy</h1>
      <p>
        At Cwtch Pottery, I fully understand how important privacy is to every
        one of my visitors and customers. I collect, use, store and retain your
        personal data in line with data protection legislation. This privacy
        notice aims to provide as much information as possible to help you
        understand how I look after your data, what your legal rights are in
        relation to that information – and how you can contact me if you have
        any questions or queries about how I look after your personal
        information.
      </p>
      <h2>Data collection</h2>
      <p>
        There are a number of ways that your personal information will be
        collected when you make contact with Cwtch Pottery – mostly though it is
        so that I can respond to queries you send, or to process orders you have
        made. You can contact me through: 
        <ul>
        <li>SMS (Text Message)</li>
        <li>Facebook</li>
        <li>Instagram</li>
        <li>Email</li>
        </ul>
      </p>
      <p>
        If you purchase from me I accept bank transfer or cash. If you choose to pay via BACS transfer, I will
        send bank details for Cwtch Pottery in order for you to do so.
      </p>
      <h2>Data processing</h2>
      <p>
        I may process information about you to answer your query or process an
        order. This may include your name, address, email address, postal
        address and telephone number – depending on what information you provide
        to me. I will receive this either directly from you for example in an
        email, FB message or by SMS text. In all cases I receive only the
        information you choose to provide – and it will be used to correspond
        with you until your order is completed. Following completion I may keep
        the information you provide. If you do not wish me to do this please let
        me know during our conversations.
      </p>
      <p>
        I may process information you send to me for publication on my website –
        for example, if you email feedback on an order you have received, I may
        share this on my website and/or social media platforms. I will always
        assume you agree for me to use your personal information in this way,
        please let me know if you would prefer me not to.
      </p>
      <h2>Cookies</h2>
      <p>
        Most website use cookies that store little bits of information on your
        computer when you visit. They are used to make websites work correctly.
        The data they use isn’t really data about you – it’s more about the
        device you use to access the website, and the location of the device.
        This is necessary for the website to function. This cookie is issued
        each time you visit our website, to identify you (well your device) as a
        unique user, purely for access and security purposes. It is retained
        only for that active browser session you can set your browser to block
        cookies, but some websites may not work if you do that.
      </p>
      <h2>Data storage</h2>
      <p>
        How long I keep your data will depend on why I have it. Mostly – it’s not
        long – I only keep your data as long as I need to answer your query or
        process your order. If you are a regular customer, I may keep your
        address or email information longer, so I don’t need to keep asking you
        every time. You can ask me to remove
        your details at any time.
      </p>
      <p>
        I am required to keep invoices and details of sales etc – which might
        include your name and address, and sometimes your email address. This is
        a requirement – should I need to submit information to HMRC. But like
        everything else I do – that information is kept safe and secure and
        accessed only by Cwtch Pottery. You can ask me to delete any information
        I hold about you. This will apply only when I do not have a legal basis
        to retain that information.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.text {
  background: linear-gradient(
    90deg,
    rgba(62, 80, 93, 1) 0%,
    #4146469e 3%,
    #4146469e 97%,
    rgba(62, 80, 93, 1) 100%
  );
  padding: 3rem;
}

p, ul {
  font-size: 2rem;
  color: white;
}

h2 {
    text-align: center;
    padding: 1.3rem
}
</style>
