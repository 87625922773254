<template>
  <div class="section-body">
    <div id="banner" class="main-img-container wrapper">
      <img src="../img/services/main.webp" />
      <span class="text-center">Services</span>
    </div>

    <div class="text">
      <div class="intro">
        <h2>Popular services</h2>
      </div>

      <div class="popular-services">
        <ServiceCard
          title="Miniature pets"
          imgPath="img/services/pet1.webp"
          :details="petDetails"
        />
        <ServiceCard
          bg="#97c3be"
          title="Small buildings"
          imgPath="img/services/small1.webp"
          :details="smallDetails"
        />
        <ServiceCard
          bg="#89ad8b"
          title="Large buildings"
          imgPath="img/services/large1.webp"
          :details="smallDetails"
        />
        <br />
        <p style="margin-top: 5rem; font-size: 2rem">
          No matter how big or small your request, please get in
          <a href="#contact-details" @click="this.$root.FlashContactDetails"
            >contact</a
          >
          to arrange the details, including pricing and discussing the design.
        </p>
      </div>

      <div class="intro mt-5" style="font-size: 2rem">
        <h2>Other services</h2>
        <ul>
          <li>
            Home pottery kits - Shape your clay, glaze your creation (two
            firings included)
          </li>
          <li>Hen party workshops</li>
          <li>Birthday parties</li>
          <li>Gift vouchers available</li>
          <li>Wedding favours</li>
        </ul>
      </div>

      <hr />

      <div class="gallery pt-4">
        <h2 class="pb-2">A look at my work</h2>
        <!-- Gallery navigation -->
        <ul class="nav nav-pills my-2 mb-4" id="services-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pets-tab"
              data-bs-toggle="pill"
              data-bs-target="#pets-gallery"
              type="button"
              role="tab"
              aria-controls="pets-gallery"
              aria-selected="true"
            >
              Pets
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="buildings-tab"
              data-bs-toggle="tab"
              data-bs-target="#buildings-gallery"
              type="button"
              role="pill"
              aria-controls="buildings-gallery"
              aria-selected="false"
            >
              Buildings
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="ornaments-tab"
              data-bs-toggle="tab"
              data-bs-target="#ornaments-gallery"
              type="button"
              role="pill"
              aria-controls="ornaments-gallery"
              aria-selected="false"
            >
              Ornaments
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="crockery-tab"
              data-bs-toggle="tab"
              data-bs-target="#crockery-gallery"
              type="button"
              role="pill"
              aria-controls="crockery-gallery"
              aria-selected="false"
            >
              Crockery
            </button>
          </li>
        </ul>

        <div class="tab-content pb-4" id="services-tab-content">
          <!-- Pets -->
          <div
            id="pets-gallery"
            class="tab-pane fade show active"
            aria-labelledby="pets-tab"
            role="tabpanel"
          >
            <div v-for="x in 26" :key="x" class="pet img-container">
              <GalleryImage
                :id="'pet-img-' + x"
                :path="'img/services/pets/' + x + '.webp'"
                background="#8b9399"
              />
            </div>
          </div>
          <!-- Buildings -->
          <div
            id="buildings-gallery"
            class="tab-pane fade"
            aria-labelledby="buildings-tab"
            role="tabpanel"
          >
            <div v-for="x in 33" :key="x" class="buildings img-container">
              <GalleryImage
                :id="'building-img-' + x"
                :path="'img/services/buildings/' + x + '.webp'"
                background="#8b9399"
              />
            </div>
          </div>
          <!-- Ornaments -->
          <div
            id="ornaments-gallery"
            class="tab-pane fade"
            aria-labelledby="ornaments-tab"
            role="tabpanel"
          >
            <!-- Sub menu -->
            <ul
              class="nav nav-pills my-2 mb-4"
              id="ornaments-submenu"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="xmasOrnaments-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#xmasOrnaments-gallery"
                  type="button"
                  role="tab"
                  aria-controls="xmasOrnaments-gallery"
                  aria-selected="true"
                >
                  Christmas
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="eastOrnaments-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#eastOrnaments-gallery"
                  type="button"
                  role="tab"
                  aria-controls="eastOrnaments-gallery"
                  aria-selected="false"
                >
                  Easter
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="hallOrnaments-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#hallOrnaments-gallery"
                  type="button"
                  role="tab"
                  aria-controls="hallOrnaments-gallery"
                  aria-selected="false"
                >
                  Halloween
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="animOrnaments-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#animOrnaments-gallery"
                  type="button"
                  role="tab"
                  aria-controls="animOrnaments-gallery"
                  aria-selected="false"
                >
                  Animals
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="peplOrnaments-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#peplOrnaments-gallery"
                  type="button"
                  role="tab"
                  aria-controls="peplOrnaments-gallery"
                  aria-selected="false"
                >
                  People
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="miscOrnaments-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#miscOrnaments-gallery"
                  type="button"
                  role="tab"
                  aria-controls="miscOrnaments-gallery"
                  aria-selected="false"
                >
                  Misc
                </button>
              </li>
            </ul>
            <div class="tab-content pb-4" id="ornaments-sub-tab-menu">
              <div
                id="xmasOrnaments-gallery"
                class="tab-pane fade show active"
                aria-labelledby="xmasOrnaments-tab"
                role="tabpanel"
              >
                <div v-for="x in 31" :key="x" class="ornaments img-container">
                  <GalleryImage
                    :id="'ornament-xmas-img-' + x"
                    :path="'img/services/ornaments/christmas/' + x + '.webp'"
                    background="#8b9399"
                  />
                </div>
              </div>

              <div
                id="eastOrnaments-gallery"
                class="tab-pane fade show"
                aria-labelledby="eastOrnaments-tab"
                role="tabpanel"
              >
                <div v-for="x in 6" :key="x" class="ornaments img-container">
                  <GalleryImage
                    :id="'ornament-east-img-' + x"
                    :path="'img/services/ornaments/easter/' + x + '.webp'"
                    background="#8b9399"
                  />
                </div>
              </div>

              <div
                id="hallOrnaments-gallery"
                class="tab-pane fade show"
                aria-labelledby="hallOrnaments-tab"
                role="tabpanel"
              >
                <div v-for="x in 7" :key="x" class="ornaments img-container">
                  <GalleryImage
                    :id="'ornament-halloween-img-' + x"
                    :path="'img/services/ornaments/halloween/' + x + '.webp'"
                    background="#8b9399"
                  />
                </div>
              </div>
              <div
                id="animOrnaments-gallery"
                class="tab-pane fade show"
                aria-labelledby="animOrnaments-tab"
                role="tabpanel"
              >
                <div v-for="x in 15" :key="x" class="ornaments img-container">
                  <GalleryImage
                    :id="'ornament-animal-img-' + x"
                    :path="'img/services/ornaments/animals/' + x + '.webp'"
                    background="#8b9399"
                  />
                </div>
              </div>
              <div
                id="peplOrnaments-gallery"
                class="tab-pane fade show"
                aria-labelledby="peplOrnaments-tab"
                role="tabpanel"
              >
                <div v-for="x in 24" :key="x" class="ornaments img-container">
                  <GalleryImage
                    :id="'ornament-people-img-' + x"
                    :path="'img/services/ornaments/people/' + x + '.webp'"
                    background="#8b9399"
                  />
                </div>
              </div>
              <div
                id="miscOrnaments-gallery"
                class="tab-pane fade show"
                aria-labelledby="miscOrnaments-tab"
                role="tabpanel"
              >
                <div v-for="x in 64" :key="x" class="ornaments img-container">
                  <GalleryImage
                    :id="'ornament-misc-img-' + x"
                    :path="'img/services/ornaments/misc/' + x + '.webp'"
                    background="#8b9399"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Crockery -->
          <div
            id="crockery-gallery"
            class="accordion-collapse collapse"
            aria-labelledby="crockery-tab"
            role="tabpanel"
          >
            <div v-for="x in 6" :key="x" class="crockery img-container">
              <GalleryImage
                :id="'crockery-img-' + x"
                :path="'img/services/crockery/' + x + '.webp'"
                background="#8b9399"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceCard from "../components/ServiceCard.vue";
import GalleryImage from "../components/GalleryImage.vue";
import "bootstrap/dist/js/bootstrap";

export default {
  name: "Services",
  components: {
    ServiceCard,
    GalleryImage,
  },
  data() {
    return {
      petDetails: [
        "Have your pet recreated in miniature form!",
        "£?",
        "Estimated time: ? weeks",
      ],
      smallDetails: ["Fill in", "£?", "Estimated time: ? weeks"],
      largeDetails: ["Fill in", "£?", "Estimated time: ? weeks"],
      allOrnaments: true,
      hallOrnaments: false,
      xmasOrnaments: false,
      eastOrnaments: false,
      miscOrnaments: false,
    };
  },
  methods: {
    ToggleOrnaments(type) {
      if (type == "all") {
        this.allOrnaments = true;
        this.hallOrnaments = false;
        this.xmasOrnaments = false;
        this.eastOrnaments = false;
        this.miscOrnaments = false;
      } else {
        switch (type) {
          case "hall":
            this.hallOrnaments = !this.hallOrnaments;
            break;
          case "xmas":
            this.xmasOrnaments = !this.xmasOrnaments;
            break;
          case "east":
            this.eastOrnaments = !this.eastOrnaments;
            break;
          case "misc":
            this.miscOrnaments = !this.miscOrnaments;
            break;
        }
        this.allOrnaments =
          (this.hallOrnaments &&
            this.xmasOrnaments &&
            this.eastOrnaments &&
            this.miscOrnaments) ||
          (!this.hallOrnaments &&
            !this.xmasOrnaments &&
            !this.eastOrnaments &&
            !this.miscOrnaments);
      }
    },
  },
  watch: {
    allOrnaments: function (val) {
      this.allOrnaments = val;
      if (val) {
        this.hallOrnaments = false;
        this.xmasOrnaments = false;
        this.eastOrnaments = false;
        this.miscOrnaments = false;
      }
    },
  },
};
</script>

<style scoped>
.ornaments-sub-container {
  display: inline;
}

#services-accordion {
  padding-bottom: 1.2rem;
}

.popular-services {
  width: 100%;
  text-align: center;
}

.gallery h4 {
  text-align: left;
}

.gallery ul {
  place-content: center;
  font-size: 1.2rem;
  font-family: "Georgia Pro Cond";
  font-weight: bold;
}

.gallery .nav-link.active {
  background-color: #9faec5;
  color: #fff !important;
}

.gallery .nav-link:hover {
  color: #7bc6dd;
}

.gallery .nav-link {
  color: #9faec5;
  margin-left: 35px;
}

@media (min-width: 601px) {
  .pet.img-container {
    width: 45% !important;
  }
  
  .ornaments.img-container {
    width: 24% !important;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }
}


#ornaments-submenu button {
  display: inline;
}

@media (max-width: 1070px) {
  .popular-services {
    text-align: -webkit-center;
    text-align: -moz-center;
  }

  .service {
    display: block;
    margin-bottom: 3rem;
  }
}
</style>
