<template>
  <div class="section-body">
    <div class="text">
      <div class="about-grid">
        <div class="about-row" style="text-align: center">
          <h1>My Story</h1>
        </div>
        <div class="about-photo"><img src="../img/about/emma.webp" /></div>
        <div class="about-text">
          <span>
            They say
            <span class="highlight"
              >"If you love the job you do, you will never work a full day in
              your life!"</span
            >
            - I couldn't agree more!
            <br />
            <span class="highlight">Cwtch Pottery</span> - established 2019 from
            a hobby that escalated into a full time job and ever expanding and
            thriving business. I fell in love with the much-needed
            <span class="highlight">calmness</span> and
            <span class="highlight">tranquillity</span> it brings whilst
            creating and pushing the realms and possibilities as a self-taught
            potter.
          </span>
        </div>
        <div class="about-text">
          <span>
            My workshop - my place to
            <span class="highlight">escape and create</span>, an Aladdin's cave
            of potters treasures - walls of
            <span class="highlight">rainbow kissed glazes</span>, tools and
            texture materials collected over the last few years always brings a
            <span class="highlight">smile</span> to my face.
            <br />
            Here, I create commissions and pieces mainly from where I live and
            grew up as a child - a place that holds a
            <span class="highlight">special place</span> in so many hearts...
            Gower and Mumbles. An area of
            <span class="highlight">Outstanding Natural Beauty</span>
            that inspires so many of my pieces and evokes so many wonderful
            memories for so many, that they are posted worldwide. From Swansea
            to Spain, America to Australia.
          </span>
        </div>
        <div class="about-photo-small">
          <img src="../img/about/workshop.webp" />
        </div>
        <div class="about-photo-small">
          <img src="../img/about/workshop2.webp" />
        </div>
        <div class="about-row-half">
          <h1>The process</h1>
          <span>I sculpt, paint and fire the clay all from my workshop.</span>
          <img id="collage" src="../img/about/collage.webp" />
        </div>
        <div class="about-row-half">
          <video width="100%" autoplay loop controls muted>
            <source src="../img/about/dog-spin.mp4" />
          </video>
        </div>
        <div class="about-row">
          <h1>Health benefits</h1>
          <span>
            Pottery has numerous health benefits, both physical and mental. Clay
            is a living material; it has
            <span class="highlight">energy, texture and temperature</span>.
            Molding clay is a
            <span class="highlight">tactile, sensory experience</span>.
            <ul>
              <li>
                Lowers <span class="highlight">heart rate</span> and
                <span class="highlight">blood pressure</span>
              </li>
              <li>Regulates <span class="highlight">breathing</span></li>
              <li>
                An <span class="highlight">outlet</span> for painful emotion
              </li>
              <li>
                <span class="highlight">Improves dexterity</span>; strenghens
                the hands, wrists and arms
              </li>
            </ul>
          </span>
        </div>
        <div class="about-row testimonies">
          <h1>Customer testimonials</h1>
            <img
              v-for="x in 4"
              :key="x"
              :src="require(`../img/about/testimonies/${x}.webp`)"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped>
span,
p {
  color: #c98c92;
  font-family: "Ballad";
  font-size: 3rem;
}

.text {
  background: linear-gradient(
    90deg,
    rgba(62, 80, 93, 1) 0%,
    #4146469e 3%,
    #4146469e 97%,
    rgba(62, 80, 93, 1) 100%
  );
  padding: 3rem;
}

.about-grid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(10, 10fr);
  grid-template-rows: repeat(10, 10fr);
  grid-auto-rows: min-content;
}

.highlight {
  color: #8cc9c3;
}

#collage {
  width: 100%;
}

/* ROW 1 */
@media (min-width: 601px) {
  #collage {
    height: 82%;
  }

  .about-row-half {
    padding-top: 5rem;
    padding-bottom: 3rem;
    font-size: 1rem;
    grid-column: span 5;
    grid-row: span 4;
  }

  .testimonies img {
    width: 50%;
    padding: 5%;
    display: inline-block;
  }

  .about-photo {
    grid-row: span 6;
    grid-column: span 5;
    margin-bottom: 5rem;
  }

  .about-photo-small {
    grid-row: span 3;
    grid-column: span 5;
    margin-bottom: 5rem;
  }

  .about-text {
    grid-row: span 6;
    grid-column: span 5;
    margin-left: 3%;
  }

  .about-text span {
    font-size: 3rem;
    font-family: "Ballad";
  }
}

@media (min-width: 900px) {
  .about-text {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

@media (max-width: 600px) {
  .about-text span,
  .about-row span {
    font-size: 2.4rem;
    font-family: "Ballad";
  }

  #collage {
    padding-top: 10%;
  }

  .about-row-half {
    padding-top: 5rem;
    padding-bottom: 3rem;
    font-size: 1rem;
    grid-column: span 10;
    grid-row: span 4;
  }

  .testimonies img {
    padding: 5%;
    width: 100%;
  }

  .about-photo,
  .about-photo-small {
    grid-row: span 6;
    grid-column: span 10;
  }

  .about-text {
    grid-row: span 6;
    grid-column: span 10;
  }
}

.about-photo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.about-photo-small img {
  height: 85%;
  width: 100%;
  object-fit: contain;
}

.about-row {
  padding-top: 5rem;
  padding-bottom: 3rem;
  font-size: 1rem;
  grid-column: span 10;
  grid-row: span 4;
}

.about-row h1 {
  text-align: center;
}

/* Remove all below -- for template testing purposes */
.about-grid {
  /* background: rgb(62, 80, 93);
  background: linear-gradient(
    90deg,
    rgba(62, 80, 93, 1) 0%,
    #97b0c3 50%,
    rgba(62, 80, 93, 1) 100%
  ); */
  min-height: 80vh;
}
.about-photo {
  /* background: rgb(150, 150, 198); */
  text-align: center;
  font-size: 30px;
}
</style>
