<template>
  <div class="row">
    <div class="col-1">
        <img id="icon-bullet-icon" :src="require(`../${imgSrc}`)" />
    </div>
    <div id="icon-bullet-text" class="col">
        {{ text }} <a v-if="url" @click="this.$root.FlashContactDetails" :href="url.link">{{ url.text }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconBullet",
  props: {
    imgSrc: String,
    text: String,
    url: Object,
    size: String,
  },
  computed: {
    width: function() {
      return this.size ? this.size : "2.5rem"
    }
  }
};
</script>

<style scoped>
#icon-bullet-icon {
    width: v-bind(width);
}

#icon-bullet-text {
    display:inline-block;
    align-self: center;
}

@media (max-width: 600px) {
  .col-1 {
    margin-right: 1.1rem
  }
}
</style>