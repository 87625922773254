<template>
  <div id="front-page-carousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item house-slide active">
        <div class="wrapper">
          <img src="../img/front/house.webp" />
          <span>
            pottery, made to order
            <br />
            <router-link to="/Services#banner" class="btn"
              >see services</router-link
            >
          </span>
        </div>
      </div>
      <div class="carousel-item learners-slide">
        <div class="wrapper">
          <img src="../img/front/learners.webp" />
          <span>
            learners classes
            <br />
            <router-link to="/Classes#banner" class="btn"
              >more info</router-link
            >
          </span>
        </div>
      </div>
      <div class="carousel-item pet-slide">
        <div class="wrapper">
          <img src="../img/front/dog-clay.webp" />
          <span>
            lovingly crafted pet miniatures
            <br />
            <router-link to="/Services#banner" class="btn"
              >see more</router-link
            >
          </span>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#front-page-carousel"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#front-page-carousel"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
#front-page-carousel {
  text-align: center;
  width: 100%;
}

#front-page-carousel {
  height: min-content;
}

@media (min-width: 1141px) {
  #front-page-carousel .carousel-item img {
    height: 800px;
  }
}

@media (max-width: 1140px) {
  #front-page-carousel .carousel-item img {
    height: 600px;
  }
}

@media (max-width: 899px) {
  #front-page-carousel .carousel-item img {
    height: 300px;
    max-width: 100%;
    object-fit: cover;
  }
}

.wrapper {
  position: relative;
  text-align: center;
  width: fit-content;
}

@media (min-width: 1450px) {
  .house-slide .wrapper span {
    top: 10%;
    left: 67%;
  }

  .learners-slide .wrapper span {
    top: 22%;
    left: 62%;
  }

  .pet-slide .wrapper span {
    top: 5%;
    left: 62%;
  }
}

@media (max-width: 1449px) {
  .wrapper {
    max-width: 100%;
  }

  .wrapper span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
}

#front-page-carousel .carousel-item {
  overflow: hidden;
  max-height: 800px;
  max-width: 100%;
  text-align: -webkit-center;
  color: #e7e7e7;
}

@media (min-width: 350px) {
  #front-page-carousel .carousel-item {
    font-size: 3.2rem;
  }
}

@media (max-width: 349px) {
  #front-page-carousel .carousel-item {
    font-size: 2rem;
  }
}

#front-page-carousel .carousel-item span {
  font-family: "Georgia Pro Cond";
  font-weight: 800;
  position: absolute;
}

.btn {
  border-radius: 1.25rem;
}

.house-slide .btn {
  background-color: #cbd461;
  border-color: #cbd461;
  color: #db3fa8;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 14px;
  font-family: "Helvetica Neue", sans-serif;
}

.learners-slide .btn {
  background-color: #c45743;
  border-color: #c45743;
  color: #e0ded9;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 14px;
  font-family: "Helvetica Neue", sans-serif;
}

.pet-slide .btn {
  background-color: #f3e412;
  border-color: #f3e412;
  color: #5a8b37;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 14px;
  font-family: "Helvetica Neue", sans-serif;
}
</style>