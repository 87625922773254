import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import Classes from '../views/Classes.vue'
import About from '../views/About.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/Services',
    name: 'Services',
    component: Services
},
{
    path: '/Classes',
    name: 'Classes',
    component: Classes
},
{
    path: '/About',
    name: 'About',
    component: About
},
{
    path: '/PrivacyPolicy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
}
]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
    },
    routes
})

export default router