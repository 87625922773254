<template>
  <header class="cwtch-header">
    <nav class="navbar-expand-lg">
      <div class="row logo">
        <img src="./img/logo.png" />
      </div>
      <div class="row">
        <!-- <div class="col-3"></div> -->
        <div class="menu col-12">
          <div class="container-fluid" style="float: left;">
            <button
              class="navbar-light navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#cwtch-menu"
              aria-controls="cwtch-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="cwtch-menu">
              <ul>
                <li><router-link to="/">Home</router-link></li>
                <li>
                  <router-link to="/Services#banner">Services</router-link>
                </li>
                <li><router-link to="/Classes#banner">Classes</router-link></li>
                <li><router-link to="/About">About</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="col-3"></div> -->
      </div>
      <div class="row">
        <div class="col-2"></div>
        <div class="slogan col-8" style="text-align: center; margin-top: 10px">
          <span style="font-size: 14px; colour: grey; text-align: center">
            If you can imagine it, I can make it. The possibilities are endless.
          </span>
        </div>
        <div class="col-2" id="contact-details-col">
          <div id="contact-details" class="pb-2">
            <q-chip icon="phone"
              ><a href="tel:+447882525170">+44 7882 525170</a></q-chip
            >
            <q-chip icon="mail"
              ><a href="mailto:cwtchpottery@hotmail.com"
                >cwtchpottery@hotmail.com</a
              ></q-chip
            >
          </div>
        </div>
      </div>
    </nav>
  </header>

  <div class="body">
    <router-view />
  </div>

  <Footer />
</template>

<script>
import { ref } from "vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import Footer from "./components/Footer.vue";

export default {
  name: "LayoutDefault",
  components: {
    Footer,
  },
  setup() {
    return {
      leftDrawerOpen: ref(false),
    };
  },
  methods: {
    FlashContactDetails() {
      var div = document.getElementById("contact-details");

      div.querySelectorAll(".q-chip").forEach(function (el) {
        setTimeout(function () {
          el.style.background = "#e1e1e1";
        }, 200);
        setTimeout(function () {
          el.style.background = "#353841";
        }, 1000);
      });
    },
  },
};
</script>

<style>
/* Other colours to try: #e7e7e7 */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  font-weight: lighter;
}

.body {
  font-family: "Ballad";
}

@media (min-width: 1141px) {
  .main-img-container img {
    height: 800px;
  }
}

@media (max-width: 1140px) {
  .main-img-container img {
    width: 100%;
  }

  .container-fluid {
    text-align: center;
  }

  .container-fluid ul {
    padding: 0px;
  }
}

.main-img-container {
  margin-bottom: 30px;
  text-align: -webkit-center;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.main-img-container span {
  font-family: "Ballad";
  font-size: 15rem;
  color: white;
}

header nav {
  margin: 0px !important;
}
.wrapper {
  position: relative;
  text-align: center;
}

footer div {
  margin: 0px !important;
}
.wrapper .text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1200px) {
  .section-body .text {
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
  }
}

.section-body .text {
  color: #efefef;
}

.section-body .text a {
  color: #d9ffd9;
}

.section-body .intro {
  font-size: 16px;
  margin-bottom: 30px;
}

.section-body .gallery {
  text-align: center;
}

.section-body .gallery .img-container {
  height: 400px;
  width: 350px;
  margin: 0.5%;
  display: inline-block;
}

.section-body .text h2 {
  font-family: "Ballad";
  font-size: 70px;
}

.section-body .text h1 {
  font-family: "Ballad";
}

.body {
  /* background-color: #e5cebc; */
  /* background-color: #c3c1c1; */
  background-color: #3e505d;
}

@media (min-width: 1141px) {
  .body {
    min-height: 80vh;
  }
}

@media (max-width: 1140px) {
  .body {
    min-height: fit-content;
  }
}

.menu ul li {
  font-size: 20px;
  font-family: "Ballad";
  font-weight: 500;
  letter-spacing: 2px;
}

.slogan span {
  font-size: 2.5rem !important;
  font-family: "Ballad";
  font-weight: 100;
  opacity: 0.9;
}

.menu ul {
  padding-top: 15px;
}

#cwtch-menu {
  place-content: center;
}

.cwtch-header {
  color: #dfdfed;
}

.cwtch-header .slogan {
  position: relative;
  float: left;
  /* margin: 16px 46px; */
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 2px;
}

.cwtch-header .logo {
  place-content: center;
  padding-top: 1rem;
}

.cwtch-header .logo img {
  height: 9rem;
  width: auto;
}

.cwtch-header nav {
  background-color: #596981;
  position: relative;
  width: 100%;
  height: fit-content;
}

.cwtch-header nav ul {
  line-height: 60px;
  list-style: none;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  color: rgb(0, 0, 0);
  text-align: center;
  margin: 0;
}

.cwtch-header nav ul li {
  display: inline-block;
}

@media (min-width: 1141px) {
  .cwtch-header nav ul li {
    padding: 16px 35px;
  }
}

@media (max-width: 1140px) {
  .cwtch-header nav ul li {
    padding: 0px 100px;
  }
}

.cwtch-header nav ul li a {
  color: #dfdfed;
  text-decoration: none;
  font-size: 2.7rem;
}

#contact-details .q-chip {
  background: #353841;
  transition: background 0.3s;
}

#contact-details a,
#contact-details i {
  color: #9faec5;
}

@media (max-width: 1140px) {
  #contact-details-col {
    width: fit-content;
    text-align: center;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@font-face {
  font-family: "Ballad";
  font-style: normal;
  font-weight: normal;
  src: url("assets/ballad/regular.woff") format("woff");
}

@font-face {
  font-family: "Georgia Pro Cond";
  font-style: normal;
  font-weight: normal;
  src: url("assets/georgia-pro/GeorgiaPro-CondRegular.woff") format("woff");
}

/* @font-face {
    font-family: 'Georgia Pro Cond Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Cond Italic'), url('GeorgiaPro-CondItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Cond Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Cond Bold'), url('GeorgiaPro-CondBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Cond Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Cond Bold Italic'), url('GeorgiaPro-CondBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Italic'), url('GeorgiaPro-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Cond Light';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Cond Light'), url('GeorgiaPro-CondLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Light';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Light'), url('GeorgiaPro-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Cond Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Cond Light Italic'), url('GeorgiaPro-CondLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Light Italic'), url('GeorgiaPro-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Cond Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Cond Semibold'), url('GeorgiaPro-CondSemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Semibold'), url('GeorgiaPro-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Cond Semibold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Cond Semibold Italic'), url('GeorgiaPro-CondSemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Semibold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Semibold Italic'), url('GeorgiaPro-SemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Bold'), url('GeorgiaPro-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Bold Italic'), url('GeorgiaPro-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Black';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Black'), url('GeorgiaPro-Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Cond Black';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Cond Black'), url('GeorgiaPro-CondBlack.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Black Italic'), url('GeorgiaPro-BlackItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Pro Cond Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Pro Cond Black Italic'), url('GeorgiaPro-CondBlackItalic.woff') format('woff');
    } */
</style>
