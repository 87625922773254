<template>
  <img
    :id="`img-thumb-${id}`"
    class="thumbnail img-thumbnail"
    :src="require(`../${path}`)"
    data-bs-toggle="modal"
    :data-bs-target="`#img-modal-${id}`"
  />
  <div
    :id="`img-modal-${id}`"
    class="modal fade"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <img class="full-size" :src="require(`../${path}`)" />
    </div>
  </div>
</template>

<script>
export default {
  name: "GalleryImage",
  props: {
    path: String,
    id: Number,
    background: String,
  },
  computed: {
    bgcolor: function() {
      if (this.background) {
        return this.background
      }
      else {
        return "white"
      }
    }
  }
};
</script>

<style scoped>
.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: zoom-in;
}

.full-size {
  max-height: 95%;
  max-width: 95%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-dialog {
  height: 100%;
  width: 100%;
}

.img-thumbnail {
  background: v-bind(bgcolor)
}
</style>